import { CanopyClient, ClientOptions } from '@canopy-pte-ltd/canopy-toolkit'
import { navigate } from '@reach/router'
import { authStore } from '../contexts'
import config from '../config'

type credentialType = { username: string; password: string }

const clientConfig: ClientOptions = {
  apiServer: config.api.auth, persistSession: true
}

export var apiClient = new CanopyClient(clientConfig);

export const getTokenWithRestoreSession = async () => {
  await CheckValidSession()
  let token: string | null = getToken()!
  if (!token) {
    if (!authStore.isAuthenticated) {
      await authStore.restore()
    }
    token = await authStore.getAccessToken()
  }
  return token
}

export const getAuthorizationHeaders = async () =>{
  if(apiClient.session){
    return {
      Authorization: getToken(),
      'auth-username': getUsername()
    }
  } else {
    return await authStore.getAuthorizationHeaders()
  }
}

export const getToken = () => {
  try{
    if(apiClient.session){
      return apiClient.session.token
    }
  }catch(e){
    return null
  }
}
export const isAuthenticated = () => apiClient.session ? true : authStore.isAuthenticated
export const isSuperAdmin = () => apiClient.session ? (apiClient.session.role == 'Superadmin') : false
export const getUsername = () => apiClient.session ? apiClient.session.username : null
export const getDisplayName = () => {
  if(apiClient.session){
    return apiClient.session.display_name
  }
  return authStore.user.name
}



export interface ILoginErrorResponse {
  error: string
}

export const login = async ({
  username,
  password,
}: credentialType) => {
  try {
    return await apiClient.login(username, password);
  } catch (e) {
    throw e
  }
}

type verifyOtpRequest = {
  otp: string
}

export const verifyOtp = async ({ otp }: verifyOtpRequest) => {
  try {
    const responseBody = await apiClient.respondToLoginChallenge(otp);
    const { token, email, username, refresh_token } = responseBody
    return {
      token,
      email,
      username,
      refresh_token,
    }
  } catch (e: any) {
    let errorMsg = null
    if (e && e.response) {
      if(e.response.body && e.response.body.error){
        errorMsg = e.response.body.error
      }else if (e.response.data && e.response.data.error){
        errorMsg = e.response.data.error
        if(errorMsg == "Invalid parameters in the request") {
          errorMsg = "Invalid code"
        }
      }
    }
    return { error: (errorMsg||'🙀 some error occurred') } as ILoginErrorResponse
  }
}

export const logoutApi = async () => {
  try {
    if(apiClient.session){
      await apiClient.logout();
    }
    await authStore.logout()
    navigate('/login')
  } catch (e) {
    // ignored
  }
}

export const CheckValidSession = async () => {
  try{
    apiClient = await CanopyClient.restoreSession(clientConfig)
  } catch(e) {
    console.error(e)
    //navigate('/login')
  }
}

